<script setup lang="ts">
import {getSeoUrl} from "@shopware-pwa/api-client";
import {getTranslatedProperty} from "@shopware-pwa/helpers-next";
import {Category, SeoUrl} from "@shopware-pwa/types";
import {AssociativeArray} from "~/types/AssociativeArray";

/**
 * Be aware that all this only works, because we know what the navigation elements are.
 * If footer navigation is not nested in columns or contains something other than internal links,
 * or if service navigation contains something other than external links and cms pages
 * this will all break.
 */

const navigationElements = inject('swNavigation-footer-navigation') as Ref<Category[]>;
const serviceMenuElements = inject('swNavigation-service-navigation') as Ref<Category[]>;
const {apiInstance} = useShopwareContext();
const showFooterMenuItems = ref(false);
const showServiceMenuItems = ref(false);
const seoUrls = ref(<AssociativeArray<SeoUrl>>{});

const renderFooterMenu = async () => {
    if (!navigationElements.value?.length) return;

    const targetIds: string[] = [];
    navigationElements.value.forEach((element) => {
        if (!element.children) return;
        element.children.forEach((child) => {
            if (child.internalLink === null) return;
            targetIds.push(child.internalLink);
        });
    });

    if (targetIds.length > 0) {
        await getSeoUrl(
            {filter: [{type: "equalsAny", field: "foreignKey", value: targetIds}]},
            apiInstance
        ).then((result) => {
            if (result && result.elements) {
                result.elements.forEach((element) => {
                    seoUrls.value[element.foreignKey] = element;
                });
            }
        });
    }
}

watch(() => navigationElements.value, renderFooterMenu);
onMounted(renderFooterMenu);


const getSeoPathInfo = (navigationElement: Category) => {
    if (navigationElement.type === "link" && navigationElement.linkType === "external") {
        return navigationElement.externalLink;
    }

    if (navigationElement.seoUrls && navigationElement.seoUrls.length) {
        return '/' + navigationElement.seoUrls[0].seoPathInfo;
    }
    return "";
}
</script>

<template>
<footer class="px-5 sm:px-6 pt-10 pb-4 md:pt-25 bg-brand-dark mt-auto flex flex-col">
    <div class="grid grid-cols-12 container">
        <div class="col-span-12 md:col-span-2 text-center my-3">
            <NuxtLink to="/" class="self-center">
                <span class="sr-only">Bevco A/S</span>
                <NuxtImg loading="lazy" class="h-20 w-auto sm:h-15" src="/logo_white.svg" alt="Logo"/>
            </NuxtLink>
        </div>
        <div class="col-span-12 md:col-span-3 lg:col-span-2 mt-4 md:mt-0">
            <div class="border-y border-y-solid border-brand-textGray md:border-none">
                <p class="font-semibold text-white my-5 text-lg md:text-xl flex justify-between px-4 md:px-0"
                   @click="showFooterMenuItems = !showFooterMenuItems">
                    <span>{{ $t('footer.shopMore') }}</span>
                    <NuxtImg src="/arrow-right-white.svg" class="title-arrow mt-1 md:hidden" height="15" :class="{'opened': showFooterMenuItems}" />
                </p>
                <div class="hidden md:flex md:flex-col lg:flex-row my-4">
                        <ul class="list-none p-0 pr-12 my-0 inline-block">
                            <template v-for="navigationElement in navigationElements" :key="navigationElement.id">
                                <template v-for="child in navigationElement.children" :key="child.id">
                                    <li
                                        v-if="child.internalLink && seoUrls[child.internalLink]"
                                        class="pb-2 xl:pb-0"
                                    >
                                        <NuxtLink
                                            :to="'/' + seoUrls[child.internalLink].seoPathInfo"
                                            class="text-sm text-white"
                                        >
                                            {{ getTranslatedProperty(child, "name") }}
                                        </NuxtLink>
                                    </li>
                                </template>
                            </template>
                        </ul>
                </div>
                <div class="flex flex-col md:hidden px-4 md:px-0" v-if="showFooterMenuItems">
                    <ul class="list-none p-0 inline-block my-0">
                        <template v-for="navigationElement in navigationElements" :key="navigationElement.id">
                            <template v-for="child in navigationElement.children" :key="child.id">
                                <li
                                    v-if="child.internalLink && seoUrls[child.internalLink]"
                                    class="pb-3"
                                >
                                    <NuxtLink
                                        :to="'/'+seoUrls[child.internalLink].seoPathInfo"
                                        class="font-sans text-white"
                                    >
                                        {{getTranslatedProperty(child, "name")}}
                                    </NuxtLink>
                                </li>
                            </template>
                        </template>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-span-12 md:col-span-3 lg:col-span-2">
            <div class="border-b border-b-solid border-brand-textGray md:border-none">
                <p class="font-semibold text-white my-5 text-lg md:text-xl flex justify-between px-4 md:px-0"
                   @click="showServiceMenuItems = !showServiceMenuItems">
                    <span>{{ $t('footer.information') }}</span>
                    <NuxtImg src="/arrow-right-white.svg" class="title-arrow mt-1 md:hidden" height="15" :class="{'opened': showServiceMenuItems}" />
                </p>
                <div class="hidden md:flex">
                    <ul class="list-none p-0 pr-12 mb-5 inline-block mt-0">
                        <li
                            v-for="elementChild in serviceMenuElements"
                            :key="elementChild.id"
                            class="pb-2 xl:pb-0"
                        >
                            <NuxtLink
                                :to="getSeoPathInfo(elementChild)"
                                class="text-sm text-white"
                            >
                                {{ getTranslatedProperty(elementChild, "name") }}
                            </NuxtLink>
                        </li>
                    </ul>
                </div>
                <div class="flex flex-col md:hidden px-4 md:px-0" v-if="showServiceMenuItems">
                    <ul class="list-none p-0 inline-block my-0">
                        <li
                            v-for="elementChild in serviceMenuElements"
                            :key="elementChild.id"
                            class="pb-3"
                        >
                            <NuxtLink
                                :to="getSeoPathInfo(elementChild)"
                                class="font-sans text-white"
                            >
                                {{ getTranslatedProperty(elementChild, "name") }}
                            </NuxtLink>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-span-12 md:col-span-3 lg:col-span-2 my-5 px-4 md:px-0">
            <div class="flex flex-col">
                <p class="font-semibold text-white mt-0 mb-4 md:text-left text-lg md:text-xl">
                    {{ $t("footer.customerService") }}
                </p>
                <ul class="list-none p-0 my-2">
                    <li>
                        <span class="font-semibold font-sans text-white">{{$t('footer.openingTimes')}}</span>
                    </li>
                    <li>
                        <span class="font-sans text-white">{{ $t('footer.weekdays') }}</span>
                    </li>
                </ul>
                <p>
                    <NuxtLink to="/kontakt-kundeservice/" class="font-sans font-normal text-white border-b border-b-solid border-white">
                        {{ $t('footer.contact') }}
                    </NuxtLink>
                </p>
                <ul class="list-none p-0 mb-5 inline-block">
                    <li>
                        <span class="font-sans text-white">BEVCO A/S</span>
                    </li>
                    <li>
                        <span class="font-sans text-white">Blomstervej 60</span>
                    </li>
                    <li>
                        <span class="font-sans text-white">DK-8381 Tilst</span>
                    </li>
                    <li>
                        <span class="font-sans text-white">CVR 34590222</span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-span-12 lg:col-span-3 px-4 mb-12">
            <div class="flex self-center md:justify-center lg:justify-end" id="footer-socials">
                <NuxtLink to="https://instagram.com/bevcodk" target="_blank" class="mr-8">
                    <NuxtImg class="h-5" loading="lazy" src="/instagram-icon.svg" alt="Instagram"/>
                </NuxtLink>
                <NuxtLink to="https://www.facebook.com/Bevco.dk" target="_blank" class="mr-8">
                    <NuxtImg class="h-5" loading="lazy" src="/facebook-icon.svg" alt="Facebook"/>
                </NuxtLink>
                <NuxtLink to="https://www.linkedin.com/company/bevco-dk" target="_blank" class="mr-8">
                    <NuxtImg class="h-5" loading="lazy" src="/linkedin-icon.svg" alt="LinkedIn"/>
                </NuxtLink>
                <NuxtLink to="https://www.youtube.com/channel/UCw8DKQejgxnkFAbTMd1HsQA" target="_blank">
                    <NuxtImg class="h-5" loading="lazy" src="/youtube-icon.svg" alt="YouTube"/>
                </NuxtLink>
            </div>
        </div>
    </div>

    <div class="w-full max-w-[1325px] mx-auto mt-10 pt-5">
        <div class="flex flex-col lg:flex-row justify-between">
            <div class="w-full flex pl-2 pb-3">
                <NuxtImg height="18" src="/mobilepay-logo.png" loading="lazy" alt="Mobilepay logo" class="mr-3" />
                <NuxtImg height="18" src="/DK_Logo.png" loading="lazy" alt="DK logo" class="mr-3" />
                <NuxtImg height="18" src="/visa.png" loading="lazy" alt="Visa logo" class="mr-3" />
                <NuxtImg height="18" src="/mc_symbol.png" loading="lazy" alt="MC logo" class="mr-3" />
            </div>

            <div class="flex items-center flex-wrap sm:flex-nowrap">
                <div class="list-inline-item">
                    <a href="https://dk.trustpilot.com/review/bevco.dk" class="mx-2">
                        <NuxtImg src="/trustpilot.svg" height="14" loading="lazy" alt="Trustpilot"/>
                    </a>
                </div>
                <div class="">
                    <NuxtImg class="mx-2" src="/postnord.png" height="14" loading="lazy" alt="Postnord"/>
                </div>
                <div class="list-inline-item">
                    <NuxtImg class="mx-2" src="/gls.png" height="14" loading="lazy" alt="GLS"/>
                </div>
                <div class="list-inline-item">
                    <a href="https://findsmiley.dk/1212142" class="mx-2">
                        <NuxtImg src="/kontrolrapport.svg" height="14" loading="lazy" alt="Smiley-ordningen"/></a>
                </div>
            </div>
        </div>
    </div>
</footer>
</template>
<style scoped>
.title-arrow {
    transition: all 0.3s ease;
}
.title-arrow.opened {
    transform: rotateZ(90deg);
}

</style>
